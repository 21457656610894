import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ranking-equipe',
  templateUrl: './ranking-equipe.component.html',
  styleUrls: ['./ranking-equipe.component.scss'],
})
export class RankingEquipeComponent implements OnInit {

  add_member = false;
  user_id;
  perfil;
  user_name;

  metodo = 'wpp';

  ranking = [];

  data = new Date();

  toggleAtivo(vendedor: any) {
    vendedor.ativo = !vendedor.ativo;
  }


  goback(){
    this.location.back();
  }

  constructor( private router : Router, private http:HttpClient, private location:Location) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    this.perfil = parseInt(localStorage.getItem('perfil'));

    if (!this.user_id) {
      this.router.navigate(['/login']);
    }

    this.obter_ranking();
  }
  obter_ranking() {

    this.http.get<any>(environment.API_URL.concat('operadores/obter_ranking/' + this.user_id)).subscribe(data => {
      if (data.status == 1) {
        this.ranking = data.ranking;
      }
    });

  }
  modal_add(meio) {
    this.metodo = meio;
    this.add_member = true;
  }

}
