import { Component, Injectable, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as $ from 'jquery';

@Component({
  selector: 'app-placar',
  templateUrl: './placar.component.html',
  styleUrls: ['./placar.component.scss'],
}) @Injectable()

export class PlacarComponent implements OnInit {

  constructor(private modalController: ModalController, private route: ActivatedRoute, private router:Router) { }
 
  @Input() isOpen = true;
  @Output() ganhou_moedas = new EventEmitter();
  
  @Input() pontos_saldo;
  pontos_ganhos = 0.75;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if( changes.isOpen ){
      this.isOpen = changes.isOpen.currentValue;
      this.receber_premio();

    }

  }

  receber_premio(){

    let novo_saldo = this.pontos_saldo + this.pontos_ganhos;
    let earning = setInterval(()=>{

      if(this.pontos_ganhos > 0.1){
        this.pontos_saldo += 0.51;
        this.pontos_ganhos -= 0.51;
      }
      else{
        this.pontos_ganhos = 0;
        this.pontos_saldo = novo_saldo;
        $(".box_ganho").fadeOut(300);
        $("#novo_saldo").fadeIn(2000);
        clearInterval(earning);
      }
    }, 20);

  }

  


}
