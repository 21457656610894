import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import LocalePt from '@angular/common/locales/pt';

registerLocaleData(LocalePt, 'pt');

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ShareModule } from 'ngx-sharebuttons';
import { CountdownModule } from 'ngx-countdown';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { CadastroComponent } from './paginas/cadastro/cadastro.component';
import { LoginComponent } from './paginas/login/login.component';
import { IndexComponent } from './paginas/index/index.component';
import { HeaderComponent } from './includes/header/header.component';
import { PopupVideoComponent } from './includes/popup-video/popup-video.component';
import { HomeComponent } from './paginas/home/home.component';
import { PerfilComponent } from './includes/perfil/perfil.component';
import { AddMemberComponent } from './includes/add-member/add-member.component';
import { FooterComponent } from './includes/footer/footer.component';
import { FooterFixComponent } from './includes/footer-fix/footer-fix.component';
import { PrimeiroAcessoComponent } from './paginas/primeiro-acesso/primeiro-acesso.component';
import { PerfilUsuarioComponent } from './paginas/perfil-usuario/perfil-usuario.component';
import { PlacarComponent } from './includes/placar/placar.component';
import { ConfirmacaoComponent } from './paginas/confirmacao/confirmacao.component';
import { RegulamentomodalComponent } from './includes/regulamentomodal/regulamentomodal.component';
import { DescadastrarComponent } from './paginas/descadastrar/descadastrar.component';
import { FaqComponent } from './paginas/faq/faq.component';
import { ContagemRegressivaComponent } from './includes/contagem_regressiva/contagem_regressiva.component';
import { DescontosComponent } from './paginas/descontos/descontos.component';
import { SuporteComponent}    from './paginas/suporte/suporte.component';
import { RankingComponent } from './paginas/ranking/ranking.component';
import { LoaderComponent } from './includes/loader/loader.component';
import { CertificadosComponent } from './paginas/certificados/certificados.component';
import { EquipeComponent } from './paginas/equipe/equipe.component';
import { TreinamentoComponent } from './paginas/treinamento/treinamento.component';
import { RankingEquipeComponent } from './paginas/ranking-equipe/ranking-equipe.component';
import { AulasComponent } from './includes/aulas/aulas.component';
import { EscolaComponent } from './paginas/escola/escola.component';
import { QrCadastroComponent } from './paginas/qr-cadastro/qr-cadastro.component';



@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        PerfilComponent,
        HeaderComponent,
        IndexComponent,
        CadastroComponent,
        LoginComponent,
        PopupVideoComponent,
        AddMemberComponent,
        FooterComponent,
        FooterFixComponent,
        PrimeiroAcessoComponent,
        PerfilUsuarioComponent,
        PlacarComponent,
        ConfirmacaoComponent,
        RegulamentomodalComponent,
        DescadastrarComponent,
        FaqComponent,
        ContagemRegressivaComponent,
        DescontosComponent,
        CertificadosComponent,
        SuporteComponent,
        RankingComponent,
        LoaderComponent,
        EquipeComponent,
        TreinamentoComponent,
        RankingEquipeComponent,
        AulasComponent,
        EscolaComponent,
        QrCadastroComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        NgxUiLoaderModule,
        FontAwesomeModule,
        NgxMaskModule.forRoot(),
        CurrencyMaskModule,
        NgxUiLoaderModule,
        CountdownModule,
        ShareButtonsModule,
        ShareModule,
        AutocompleteLibModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
