import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss'],
})
export class CertificadoComponent implements OnInit {

  constructor(private route:ActivatedRoute, private router:Router, private http:HttpClient, private location:Location) { }

  public id;
  public nome;
  public cpf;
  public data;

  message = 'Gerando seu certificado...';

  ngOnInit() {
    const paramMap = this.route.snapshot.paramMap;
    const id = paramMap.get('id');

    if(!id){
      this.router.navigate(['/cadastro']);
    }

    this.id = id;
    this.obter_dados();
  }


  obter_dados(){
    
    this.http.get<any>(environment.API_URL.concat('cadastro/obter_id/'+this.id)).subscribe( data =>{
      if(data.status == 1){
        this.nome = data.user.nome;
        this.cpf =  data.user.cpf;
        this.data = this.obterDataAtual();

        setTimeout(()=>{
          this.captureScreen();
        }, 2000);

      }
      else{
        alert("Houve um erro ao processar os dados, favor entre em contato com o administrador do sistema");
      }
    })
    
  }

  captureScreen() {
    const elementToCapture = document.getElementById('certificado');

    html2canvas(elementToCapture).then(canvas => {
        // Criando um canvas temporário para girar a imagem em 90 graus na direção contrária
        const rotatedCanvas = document.createElement('canvas');
        const rotatedContext = rotatedCanvas.getContext('2d');
        rotatedCanvas.width = canvas.height; // trocando a largura pelo comprimento
        rotatedCanvas.height = canvas.width; // trocando o comprimento pela largura
        rotatedContext.translate(rotatedCanvas.width / 2, rotatedCanvas.height / 2);
        rotatedContext.rotate(-Math.PI / 2); // utilizando o sinal negativo para girar na direção contrária
        rotatedContext.drawImage(canvas, -canvas.width / 2, -canvas.height / 2);
    
        const rotatedImageData = rotatedCanvas.toDataURL('image/png');
    
        // Criando um link de download
        const downloadLink = document.createElement('a');
        downloadLink.href = rotatedImageData;
        downloadLink.download = `${this.nome}_${this.cpf}.png`;
    
        // Adicionando o link de download ao corpo da página
        document.body.appendChild(downloadLink);
    
        downloadLink.click();
    
        document.body.removeChild(downloadLink);

        setTimeout(()=>{
          this.message = 'Certificado baixado com sucesso!';
        }, 2000);
    });
  }

  obterDataAtual() {
    var data = new Date();
    var dia = this.padZero(data.getDate());
    var mes = this.padZero(data.getMonth() + 1); // Os meses são indexados a partir de zero
    var ano = data.getFullYear();
  
    return dia + '/' + mes + '/' + ano;
  }

  padZero(numero) {
    return numero < 10 ? '0' + numero : numero;
  }

  goback(){
    this.location.back();
    setTimeout( () => {
      window.location.reload();
    }, 500);
  }

}
