import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';


import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {

  public regulamento;
  public perfil = parseInt(localStorage.getItem('perfil'));
  public certificado;

  public menuOperador = [ 
    { title: 'Home',                    subtitle: 'Vejas as novidades', url: '/home',      },
    { title: 'Perfil',                  subtitle: 'Seus dados',      url: '/perfil-usuario',      },
    { title: 'Clube de Descontos',      subtitle: 'Grandes marcas parceiras',   url: '/descontos'  },
    { title: 'Treinamento',             subtitle: 'Aprimore seus conhecimentos',   url: '/treinamentos'  },
    { title: 'Certificados',            subtitle: 'Seus certificados',   url: '/certificados'  },
    { title: 'Ranking',                 subtitle: 'Veja seu progresso',   url: '/ranking'  },
  ];

   public menuGerente = [ 
    { title: 'Home',                    subtitle: 'Vejas as novidades',         url: '/home',      },  
    { title: 'Perfil',                  subtitle: 'Seus dados',                 url: '/perfil-usuario',      },
    { title: 'Clube de Descontos',      subtitle: 'Grandes marcas parceiras',   url: '/descontos'  },
    { title: 'Equipe',                  subtitle: 'Atualize os operadores',     url: '/equipe',    },
    { title: 'Certificados da Equipe',  subtitle: 'Veja seus certificados',     url: '/certificados'    },
    { title: 'Ranking da equipe',       subtitle: 'Veja o progresso da sua equipe', url: '/ranking'    },
  ];

  private routerSubscription: Subscription;


  constructor( private platform: Platform, private router : Router, private route : ActivatedRoute // private splashScreen: SplashScreen, // private statusBar: StatusBar 
  ) {
    this.initializeApp();
  }

  ngOnInit(): void {


    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleChanges();
      }
    });
    
  }

  handleChanges(){

    if(this.router.url.includes('certificado/')){
      this.certificado = true;
      $("ion-app").css({'max-width': 'none'});
    }
    else{
      this.certificado = false;
      $("ion-app").css({'max-width': '860px', 'margin': 'auto'});
    }

  }


  initializeApp() {
    this.platform.ready().then(() => {
     // this.statusBar.styleDefault();
     // this.splashScreen.hide();
    });
  } 

  abrir_regulamento(){
    this.regulamento = true;
  }

  logout(){
    localStorage.clear();
    location.reload();
  }

  fechar_menu(){
    let menu =  $('ion-menu');
    menu.removeClass('show-menu');
    let backdrop =  $('ion-backdrop');
    backdrop.removeClass('show-backdrop');
  }

}
