import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qr-cadastro',
  templateUrl: './qr-cadastro.component.html',
  styleUrls: ['./qr-cadastro.component.scss'],
})
export class QrCadastroComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {

    if(localStorage.getItem('userId')){
      this.router.navigate(['/home']);
    }
  }

}
