import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  user_id;
  user_name;
  
  perfil;
  public items:any = [];

  @Input()
  limite: string;
  
  constructor(private router:Router, private http: HttpClient) { }

  ngOnInit() {
    
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    this.perfil = localStorage.getItem('perfil');
    this.obter_faq();
  }


  obter_faq(){
    this.http.get<any>(environment.API_URL.concat('faq/index/'+this.perfil)).subscribe( data =>{
        if(this.limite){
            this.items = data.slice(0,this.limite);
        } else {
            this.items = data;
        }
    });
  }
}
