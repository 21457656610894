import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CadastroComponent } from './paginas/cadastro/cadastro.component';
import { HomeComponent } from './paginas/home/home.component';
import { IndexComponent } from './paginas/index/index.component';
import { LoginComponent } from './paginas/login/login.component';
import { PrimeiroAcessoComponent } from './paginas/primeiro-acesso/primeiro-acesso.component';
import { PerfilUsuarioComponent } from './paginas/perfil-usuario/perfil-usuario.component';
import { ConfirmacaoComponent } from './paginas/confirmacao/confirmacao.component';
import { DescadastrarComponent } from './paginas/descadastrar/descadastrar.component';
import { FaqComponent } from './paginas/faq/faq.component';
import { DescontosComponent } from './paginas/descontos/descontos.component';
import { SuporteComponent } from './paginas/suporte/suporte.component';
import { RankingComponent } from './paginas/ranking/ranking.component';
import { QrCadastroComponent } from './paginas/qr-cadastro/qr-cadastro.component';
import { CertificadoComponent } from './paginas/certificado/certificado.component';
import { CertificadosComponent } from './paginas/certificados/certificados.component';
import { EquipeComponent } from './paginas/equipe/equipe.component';
import { TreinamentoComponent } from './paginas/treinamento/treinamento.component';
import { RankingEquipeComponent } from './paginas/ranking-equipe/ranking-equipe.component';
import { EscolaComponent } from './paginas/escola/escola.component';

const routes: Routes = [
  {    path: 'folder/:id',
      loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {    path: '',                    component: LoginComponent,    pathMatch: 'full'  },
  {    path: 'qr',                  component: QrCadastroComponent },
  {    path: 'cadastro',            component: CadastroComponent  },
  {    path: 'certificado/:id',     component: CertificadoComponent  },
  {    path: 'certificados',        component: CertificadosComponent  },
  {    path: 'login',               component: LoginComponent  },
  {    path: 'home',                component: HomeComponent  },
  {    path: 'perfil-usuario',      component: PerfilUsuarioComponent},
  {    path: 'faq',                 component: FaqComponent},
  {    path: 'descontos',           component: DescontosComponent},
  {    path: 'primeiro-acesso',     component: PrimeiroAcessoComponent},
  {    path: 'equipe',              component: EquipeComponent},
  {    path: 'treinamento',         component: TreinamentoComponent},
  {    path: 'ranking-equipe',      component: RankingEquipeComponent},
  {    path: 'treinamentos',        component: EscolaComponent},
  {    path: 'ranking',             component: RankingEquipeComponent },
  {    path: 'confirmacao',         component: ConfirmacaoComponent},
  //{    path: 'cadastrar',           component: CadastroComponent  },
  //{    path: 'primeiro-acesso',     component: PrimeiroAcessoComponent},
  //{    path: 'descadastrar',        component: DescadastrarComponent},
  //{    path: 'suporte',             component: SuporteComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
