import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  buscar_clientes() {
    let id_usuario = localStorage.getItem('userId');
    return this.http.get<any>(environment.API_URL.concat('clientes/buscar/' + id_usuario));
  }

  buscar_logo_cliente(id_cliente: any) {
    return this.http.get<any>(environment.API_URL.concat('clientes/get_cliente_logo/' + id_cliente));
  }

  buscar_maquinas() {
    return this.http.get<any>(environment.API_URL.concat('maquinas/buscar/'));
  }

  buscar_dados(id_maquina: string, data_inicio: string, data_fim: string, id_usuario: any) {
    return this.http.get<any>(environment.API_URL.concat('maquinas/buscar_relatorio/' + id_maquina + '/' + data_inicio + '/' + data_fim + '/' + id_usuario));
  }

  buscar_dados_indicadores(id_maquina: string, data_inicio: string, data_fim: string, id_usuario: any) {
    return this.http.get<any>(environment.API_URL.concat('maquinas/buscar_relatorio_indicadores/' + id_maquina + '/' + data_inicio + '/' + data_fim + '/' + id_usuario));
  }

  buscar_usuarios(busca: string = '', pagina: number = -1) {
    let id_user = localStorage.getItem('userId');
   
    let url = environment.API_URL.concat('usuarios/listar/' + id_user + '/' + pagina);
    if(busca){
      url = url.concat('/' + busca);
    }
    return this.http.get<any>(url);
  }

  buscar_usuario() {
    let id_usuario = localStorage.getItem('userId');
    return this.http.get<any>(environment.API_URL.concat('usuarios/buscar/' + id_usuario));
  }

  buscar_usuario_by_id(id_usuario:any) {
    return this.http.get<any>(environment.API_URL.concat('usuarios/buscar/' + id_usuario));
  }

  enviar_usuario(usuario: any) {
    if (usuario.id) {
      return this.http.post<any>(environment.API_URL.concat('usuarios/editar'), usuario);
    }
    return this.http.post<any>(environment.API_URL.concat('usuarios/cadastrar'), usuario);
  }
  enviar_relatorio_teste(usuario: any) {
    return this.http.get<any>(environment.API_URL.concat('usuarios/teste_relatorios/' + usuario.id));
  }

  apagar_usuario(id: any) {
    return this.http.get<any>(environment.API_URL.concat('usuarios/apagar_usuario/' + id));
  }
}
